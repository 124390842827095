<template>
  <section id="calendar">
    <div class="row">
      <fieldset class="form-group">
        <div class="input-group">
          <input type="text"
                 class="form-control"
                 :class="{ 'is-valid': label.isValid, 'is-invalid': label.isValid === false }"
                 placeholder="Enter label"
                 v-model="label.value"
                 @blur="validateLabel()"
          />
          <datepicker
              v-model="pickedDate.value"
              inputFormat="dd/MM/yyyy"
              class="form-control"
              :class="{ 'is-valid': pickedDate.isValid, 'is-invalid': pickedDate.isValid === false }"
              placeholder="Pick date"
              @blur="validateDate()"
          />
          <select
              class="form-control"
              :class="{ 'is-valid': specialDateType.isValid, 'is-invalid': specialDateType.isValid === false }"
              v-model="specialDateType.value"
              @blur="validateSpecialDateType()"
          >
            <option value="" disabled hidden>Select date type</option>
            <option v-for="type in specialDateTypes" :value="type.slug">{{ type.readable }}</option>
          </select>
          <button class="btn btn-primary" type="button" :disabled="!formValidated || adding" @click="addSpecialDate()">Add Date</button>
          <label v-if="error" class="control-label">{{error}}</label>
        </div>
      </fieldset>
    </div>
    <div class="row">
      <div class="card col-md-12">
        <div class="card-body">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">Label</th>
              <th scope="col">Date</th>
              <th scope="col">Type</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr class="specialDate" v-for="specialDate in specialDates">
              <th scope="row">{{ specialDate.label }}</th>
              <td>{{ specialDate.date }}</td>
              <td>{{ stringifySlug(specialDate.special_date_type) }}</td>
              <td>
                <button type="button" class="btn btn-primary btn-sm" @click="removeSpecialDate(specialDate)">Remove</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import APIService from "@/services/APIService";
import UtilService from "@/services/UtilService";

export default {
  name: "Calendar",
  data() {
    return {
      specialDateTypes: [],
      specialDates: [],
      resident: null,
      includeResident: false,
      filter: {},
      specialDateType: {
        value: "",
        error: null,
        isValid: undefined
      },
      label: {
        value: null,
        error: null,
        isValid: undefined
      },
      pickedDate: {
        value: null,
        error: null,
        isValid: undefined
      },
      error: null,
      adding: false
    }
  },
  computed: {
    formValidated() {
      return this.specialDateType.isValid && this.label.isValid && this.pickedDate.isValid;
    }
  },
  methods: {
    stringifySlug: UtilService.stringifySlug,
    validateSpecialDateType: function() {
      this.specialDateType.isValid = this.specialDateTypes.some(type => type.slug === this.specialDateType.value);
    },
    validateLabel: function() {
      this.label.isValid = !!this.label.value;
    },
    validateDate: function() {
      this.pickedDate.isValid = this.pickedDate.value instanceof Date;
    },
    addSpecialDate: async function() {
      this.adding = true;
      await APIService.addSpecialDate(this.label.value, UtilService.dateToSqlDateString(this.pickedDate.value), this.specialDateType.value);
      this.specialDateType = {
        value: null,
        error: null,
        isValid: undefined
      };
      this.label = {
        value: null,
        error: null,
        isValid: undefined
      };
      this.pickedDate = {
        value: null,
            error: null,
            isValid: undefined
      };
      this.specialDates = await APIService.getSpecialDates(this.filter);
      this.adding = false;
    },
    async removeSpecialDate(specialDate) {
      await APIService.deleteSpecialDate(specialDate.special_date_id);
      this.specialDates = await APIService.getSpecialDates(this.filter);
    }
  },
  beforeCreate() {
    APIService.getSpecialDateTypes()
        .then(specialDateTypes => {
          this.specialDateTypes = specialDateTypes.map(type => {
            return {
              slug: type,
              readable: UtilService.stringifySlug(type)
            }
          });
        });
    APIService.getSpecialDates(this.filter)
        .then(specialDates => this.specialDates = specialDates);
  }
}
</script>

<style scoped>
tr.specialDate {
  line-height: 2.5;
}

#calendar {
  min-height: 350px;
  margin: 0;
  padding: 0;
}
</style>
